<template>
  <div>
    <gql-table
      ref="refundTable"
      :query="query"
      :fields="columns"
      query-type="refunds"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="actions"
      :title="title"
      @edit="onRefund"
      @complete="onComplete"
      @decline="onDecline"
      @remove="onDelete"
    >
      <template #cell(order)="data">
        <b-link :to="{name: 'order-detail', params: {id: data.item.order.order_number}}">
          {{ data.item.order.order_number }}
        </b-link>
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.order.user.id } }">
              {{ data.item.order.user.firstName }} {{ data.item.order.user.lastName }}
              <risk-score-badge
                v-if="data.item.order.user.risk_score"
                class="mx-1"
                :score-results="data.item.order.user.risk_score_results"
                :score="data.item.order.user.risk_score"
              />
              <br>
              <small>{{ data.item.order.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.order.user.tags"
              :extras-tags="getUserExtraTags(data.item.order.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(form)="data">
        <span
          v-for="(i, key) in getForm(data.item.form)"
          :key="key"
        >
          <strong> {{ (key.charAt(0).toUpperCase() + key.slice(1)).replace('_', ' ') }}:</strong> {{ i }};
        </span>
      </template>

      <template #cell(processor)="data">
        {{ data.item.order.processor }}
      </template>

      <template #cell(price)="data">
        {{ formatCurrency(data.item.price, 'USD') }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${refundStates[data.item.state].variant}`">
          {{ refundStates[data.item.state].title }}
        </b-badge>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(updatedAt)="data">
        {{ formatDateTime(data.item.updatedAt) }}
      </template>
    </gql-table>
    <b-modal
      id="refundEditModal"
      title="Edit refund"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <refund-form
        :refund="refundEdit"
        @submit="onCloseRefundModal()"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BBadge, BCol, BLink, BModal, BRow,
} from 'bootstrap-vue'
import { dispatch } from 'vuex-pathify'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'
import { formatCurrency, formatDateTime } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import { REFUND_STATES } from '@/enums/refunds'
import RefundForm from '@/components/forms/order-refund/RefundForm.vue'
import OrderRefundAPI from '@/api/order-refund'
import store from '@/store'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Complete',
    icon: 'CheckIcon',
    emit: 'complete',
  },
  {
    text: 'Decline',
    icon: 'XIcon',
    emit: 'decline',
  },
  {
    text: 'Remove refund',
    icon: 'Trash2Icon',
    emit: 'remove',
  },
]

export default {
  components: {
    BModal,
    RefundForm,
    BBadge,
    TagsList,
    RiskScoreBadge,
    BRow,
    BCol,
    GqlTable,
    BLink,
  },
  props: {
    columns: Array,
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      refundStates: REFUND_STATES,
      actions,
      refundEdit: null,
      query: [
        'id',
        'form',
        'price',
        'state',
        'createdAt',
        'updatedAt',
        'comment',
        {
          order: {
            fields: [
              'id',
              'order_number',
              'state',
              'processor',
              {
                user: {
                  fields: [
                    'firstName',
                    'lastName',
                    'id',
                    'email',
                    'risk_score',
                    'risk_score_results',
                    'kyc_at',
                    'purchase_disabled',
                    { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
                  ],
                },
              },
            ],
          },
        },
      ],
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    getForm(form) {
      if (!form) return []

      Object.keys(form).forEach(key => {
        if (form[key] == null) {
          delete form[key]
        }
      })
      return form
    },
    onRefund(item) {
      dispatch('refundList/get', item.id).then(data => {
        this.refundEdit = data
        this.$bvModal.show('refundEditModal')
      })
    },
    onCloseRefundModal() {
      this.refundEdit = null
      this.$bvModal.hide('refundEditModal')
      this.$refs.refundTable.reloadAll(true)
    },
    onDelete(item) {
      Swal.fire({
        title: 'Remove this refund',
        text: 'Are you sure you want to remove this refund?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          OrderRefundAPI.deleteRefund(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Remove successful',
                  icon: 'TrashIcon',
                  text: 'Refund was successfully removed',
                  variant: 'success',
                },
              })
              this.$refs.refundTable.reloadAll(true)
            })
        }
      })
    },
    onComplete(item) {
      const payload = {
        id: item.id,
        data: { state: 'completed' },
        autoReload: false,
      }
      store.dispatch('refundList/update', payload).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: 'Entity edit was successful',
            variant: 'success',
          },
        })
        this.$refs.refundTable.reloadAll(true)
      })
    },
    onDecline(item) {
      const payload = {
        id: item.id,
        data: { state: 'declined' },
        autoReload: false,
      }
      store.dispatch('refundList/update', payload).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: 'Entity edit was successful',
            variant: 'success',
          },
        })
        this.$refs.refundTable.reloadAll(true)
      })
    },
  },
}
</script>
