import axiosInstance from '@/plugins/axios'

export default {
  createRefund(data) {
    const url = 'admin/refunds'

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  deleteRefund(refundId) {
    const url = `admin/refunds/${refundId}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },
}
