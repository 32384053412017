<template>
  <b-card>
    <b-row>
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start flex-wrap">
          <b-avatar
            v-if="!isMobile"
            variant="light-primary"
            size="104px"
            rounded
          >
            <b-img
              v-if="profileImage"
              :src="profileImage"
            />
            <UserIcon v-else />
          </b-avatar>
          <div class="d-flex flex-column ml-1">
            <div class="mb-1 d-flex flex-wrap">
              <div>
                <h4 class="mb-0">
                  {{ user.full_name }}
                </h4>
                <span class="card-text">{{ user.email }}</span>
              </div>
              <tags-list
                :tags="user.tags"
                class="m-50"
                :show-hidden-by-toggle="false"
              />
            </div>
            <div class="mt-auto">
              <b-button
                v-b-tooltip.hover.top="'Edit user'"
                variant="outline-primary"
                class="btn-icon m-50"
                @click="onEdit"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.top="'Show verification data'"
                v-b-modal.verifyModal
                variant="outline-primary"
                class="btn-icon m-50"
              >
                <feather-icon icon="UserCheckIcon" />
              </b-button>
              <b-button
                class="m-50"
                variant="outline-warning"
                @click="onRsSummary"
              >
                RS Summary
              </b-button>
              <b-button
                class="m-50"
                variant="outline-info"
                @click="onClickIpCheck"
              >
                IP checker
              </b-button>
              <b-dropdown
                class="mx-1"
                variant="outline-primary"
                right
                text="Options"
              >
                <b-dropdown-item v-b-modal.downloadSocialCertificateModal>
                  Download social certificate
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.downloadTotalPayoutCertificateModal>
                  Download total certificate
                </b-dropdown-item>
                <b-dropdown-item @click="onLoginAs">
                  Login as User
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-if="isMobile"
                variant="outline-info"
                class="btn-icon"
                @click="showUserInfo = !showUserInfo"
              >
                <feather-icon :icon="showUserInfo ? 'EyeOffIcon' : 'EyeIcon'" />
                Show user info
              </b-button>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-center mt-2">
          <div class="d-flex align-items-center mr-2 mt-50">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="CreditCardIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatCurrency(totalSpendSum, 'USD') }}
              </h5>
              <small>Total sum of user spend</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2 mt-50">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ totalActiveAccounts }}
              </h5>
              <small>Total active accounts</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2 mt-50">
            <b-avatar
              variant="light-danger"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ totalSoldAccounts }}
              </h5>
              <small>Total sold accounts</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2 mt-50">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="BarChart2Icon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ totalTrialAccounts }}
              </h5>
              <small>Total trial accounts</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2 mt-50">
            <b-avatar
              variant="light-danger"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatCurrency(totalWithdraw.withdraw, 'USD') }} | {{ totalWithdraw.payouts_count }}
              </h5>
              <small>Total withdraw</small>
            </div>
          </div>
          <!--  user P/L        -->
          <div
            class="d-flex align-items-center mr-2 mt-50"
          >
            <b-overlay :show="loadingPl">
              <b-button
                variant="outline-info"
                @click="getPl"
              >
                Show P/L
              </b-button>
            </b-overlay>
          </div>

          <!--  FR/Pass rates        -->
          <div
            v-if="fr2 === 0 && fr3 === 0 && passRates.length === 0"
            class="d-flex align-items-center mr-2 mt-50"
          >
            <b-overlay :show="loadingPassRates">
              <b-button
                variant="outline-primary"
                @click="getPassRates"
              >
                Show pass rates
              </b-button>
            </b-overlay>
          </div>
          <div
            v-if="fr2 > 0"
            class="d-flex align-items-center mr-2 mt-50"
          >
            <b-avatar
              variant="light-danger"
              rounded
            >
              <feather-icon
                icon="PercentIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatFloat(fr2) }}%
              </h5>
              <small>Funding rate Two phase account</small>
            </div>
          </div>

          <div
            v-if="fr3 > 0"
            class="d-flex align-items-center mr-2 mt-50"
          >
            <b-avatar
              variant="light-danger"
              rounded
            >
              <feather-icon
                icon="PercentIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatFloat(fr3) }}%
              </h5>
              <small>Funding rate Three phase account</small>
            </div>
          </div>

          <div
            v-for="(pr, key) in filteredPassRates"
            :key="key"
            class="d-flex align-items-center mr-2 mt-50"
          >
            <b-avatar
              variant="light-danger"
              rounded
            >
              <feather-icon
                icon="PercentIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatFloat(pr) }}%
              </h5>
              <small>Pass rate {{ key.toUpperCase().split("_")[0] }} {{ key.toUpperCase().split("_")[1] }}</small>
            </div>
          </div>

        </div>
      </b-col>
      <b-col
        v-if="!isMobile || showUserInfo"
        cols="12"
        xl="6"
        class="p-1"
      >
        <b-row>
          <b-col>
            <feather-icon
              icon="MapPinIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Contact</span>
          </b-col>
          <b-col>
            {{ user.address }}, {{ user.zipcode }} {{ user.city }} {{ user.state }}
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <feather-icon
              icon="PhoneIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Phone</span>
          </b-col>
          <b-col>
            {{ user.phone }}
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <feather-icon
              icon="GlobeIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Country</span>
          </b-col>
          <b-col>
            {{ user.country.name }}
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <feather-icon
              icon="KeyIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Registered with affiliate key</span>
          </b-col>
          <b-col>
            {{ user.affiliate ? `${user.affiliate.aff_key} (${user.affiliate.aff_custom_key}) | ${user.affiliate.user.full_name} (${user.affiliate.user.email})` : 'User wasn\'t register with affiliate link' }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <feather-icon
              icon="KeyIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Registered with affiliate key(NEW AFFILIATE)</span>
          </b-col>
          <b-col>
            {{ user.partner ? `${user.partner.aff_custom_key} | ${user.partner.user.full_name} (${user.partner.user.email})` : 'User wasn\'t register with affiliate link' }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <feather-icon
              icon="UserPlusIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Registered</span>
          </b-col>
          <b-col>
            {{ formatDateTime(user.createdAt) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <feather-icon
              icon="CloudIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Registration IP</span>
          </b-col>
          <b-col>
            {{ user.ip }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Verified at</span>
          </b-col>
          <b-col>
            {{ formatDateTime(user.kyc_at) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <feather-icon
              icon="FileTextIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Comment</span>
          </b-col>
          <b-col>
            {{ user.comment }}
          </b-col>
        </b-row>
        <b-row
          v-if="pl !== null && pl.length > 0"
          class="mt-1"
        >
          <b-col>
            <table
              v-if="!isMobile"
              class="table-b-table-default"
            >
              <tr>
                <th
                  v-for="(h, key) in Object.keys(pl[0])"
                  :key="key"
                  class="p-1"
                >
                  {{ h.toUpperCase().replaceAll('_',' ') }}
                </th>
              </tr>
              <tr
                v-for="(item, key) in pl"
                :key="key"
              >
                <td
                  v-for="(v, k) in Object.values(item)"
                  :key="k"
                >
                  {{ v }}
                </td>
              </tr>
            </table>
            <table
              v-if="plSummary"
              class="table-b-table-default"
            >
              <tr>
                <th
                  class="p-1"
                >
                  CURRENCY
                </th>
                <th
                  class="p-1"
                >
                  Total
                </th>
              </tr>
              <tr
                v-for="(v, key) in plSummary"
                :key="key"
              >
                <td>
                  {{ key }}
                </td>
                <td>
                  {{ formatNumber(v) }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <!--        active deposit-->
        <div
          v-if="activeDeposit.length > 0"
          class="mt-2"
        >
          <h4>Deposit in active accounts</h4>
          <div class="d-flex align-items-center flex-wrap mt-2">
            <div
              v-for="(accountsDeposit, key) in activeDeposit"
              :key="key"
              class="d-flex align-items-center mr-2"
            >
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ formatCurrency(accountsDeposit.deposit, accountsDeposit.currency) }}
                </h5>
                <small>{{ accountsDeposit.account_type_name.toUpperCase() }} {{ ACCOUNT_SUBTYPE[accountsDeposit.account_subtype_name].title }}</small>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="userModal"
      title="User edit"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <user-form
        :user="user"
        @submit="onEditSubmit()"
      />
    </b-modal>
    <b-modal
      id="verifyModal"
      title="Verification details"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <veriff-detail
        v-for="(veriff, key) in user.verifications"
        :key="key"
        :item="veriff"
      >
        <hr>
      </veriff-detail>
    </b-modal>
    <b-modal
      id="rsSummaryModal"
      title="Risk score summary"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <account-linkage-summary-table :advanced-filter="rsSummaryFilter" />
    </b-modal>
    <b-modal
      id="downloadSocialCertificateModal"
      title="Download social certificate"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <certificate-download-form
        :loading="downloadLoading"
        :show-color="false"
        :show-type="false"
        :show-force-generate="false"
        @download="onDownloadSocial"
      />
    </b-modal>
    <b-modal
      id="downloadTotalPayoutCertificateModal"
      title="Download total payout certificate"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <certificate-download-form
        :loading="downloadLoading"
        :show-color="false"
        :show-type="false"
        @download="onDownloadTotalPayout"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol, BButton, BModal, BImg, VBTooltip, BOverlay, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { UserIcon } from 'vue-feather-icons'
import { dispatch, get } from 'vuex-pathify'
import _groupBy from 'lodash/groupBy'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import UserForm from '@/components/forms/user/UserForm.vue'
import TagsList from '@/components/page/TagsList.vue'
import VeriffDetail from '@/components/page/JsonRecursiveList.vue'
import {
  formatDateTime, formatCurrency, formatFloat, formatNumber,
} from '@/plugins/formaters'
import { ACCOUNT_SUBTYPE } from '@/enums/accounts'
import UserAPI from '@/api/user'
import AccountLinkageSummaryTable from '@/components/entity/accountLinkageSummary/AccountLinkageSummaryTable.vue'
import CertificateDownloadForm from '@/components/forms/certificate/CertificateDownloadForm.vue'

export default {
  components: {
    CertificateDownloadForm,
    AccountLinkageSummaryTable,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BModal,
    UserIcon,
    UserForm,
    TagsList,
    BImg,
    VeriffDetail,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      downloadLoading: false,
      ACCOUNT_SUBTYPE,
      fr2: 0,
      fr3: 0,
      passRates: [],
      pl: null,
      loadingPassRates: false,
      loadingPl: false,
      rsSummaryFilter: { search: '', user_id: null, country: null },
      showUserInfo: false,
    }
  },
  computed: {
    ...get('usersList@row', {
      user: 'user_data',
      accounts: 'accounts',
      activeDeposit: 'active_deposit',
      totalWithdraw: 'total_withdraw',
      totalActiveAccounts: 'active_accounts_count',
      totalSoldAccounts: 'total_sold',
      totalSpendSum: 'total_spend',
      totalTrialAccounts: 'trials_count',
    }),
    ...get('app', ['isMobile']),
    filteredPassRates() {
      return Object.fromEntries(Object.entries(this.passRates)
        .filter(([, val]) => val > 0))
    },
    profileImage() {
      return this.user.profile_image ? `${process.env.VUE_APP_API_URL}users/${this.user.id}/avatar/${this.user.profile_image}` : null
    },
    plSummary() {
      if (this.pl === null || this.pl.length === 0) return []
      const byCurrency = _groupBy(this.pl, r => r.currency)
      Object.keys(byCurrency).forEach(c => {
        byCurrency[c] = byCurrency[c].map(item => Number(item.pl_sum)).reduce((prev, next) => prev + next)
      })

      return byCurrency
    },
  },
  methods: {
    formatNumber,
    formatDateTime,
    formatCurrency,
    formatFloat,
    onEdit() {
      this.$bvModal.show('userModal')
    },
    onEditSubmit() {
      this.$bvModal.hide('userModal')
      dispatch('usersList/get', this.user.id)
    },
    onClickIpCheck() {
      const route = this.$router.resolve({ name: 'tools-ip-check', params: { id: this.user.id } })
      window.open(route.href)
    },
    onRsSummary() {
      this.rsSummaryFilter.user_id = this.user.id
      this.rsSummaryFilter.country = this.user.country.id
      this.$bvModal.show('rsSummaryModal')
    },
    getPassRates() {
      this.loadingPassRates = true
      UserAPI.getUserPassRates(this.user.id).then(response => {
        this.fr2 = response.fr2
        this.fr3 = response.fr3
        this.passRates = response.pass_rates
      }).finally(() => {
        this.loadingPassRates = false
      })
    },
    getPl() {
      this.loadingPl = true
      UserAPI.getUserPL(this.user.id).then(response => {
        this.pl = response
      }).finally(() => {
        this.loadingPl = false
      })
    },
    onDownloadSocial(form) {
      this.downloadLoading = true

      UserAPI.getCertificate(this.user.email, this.user.id, form.size, 'social')
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              text: error.status === 400 ? 'Certificate is not generated yet' : error.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.downloadLoading = false
          this.$bvModal.hide('downloadSocialCertificateModal')
        })
    },
    onDownloadTotalPayout(form) {
      this.downloadLoading = true

      UserAPI.getCertificate(this.user.email, this.user.id, form.size, 'total', true)
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              text: error.status === 400 ? 'Certificate is not generated yet' : error.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.downloadLoading = false
          this.$bvModal.hide('downloadTotalPayoutCertificateModal')
        })
    },
    onLoginAs() {
      UserAPI.postLoginAs(this.user.id)
        .then(response => {
          Swal.fire({
            title: 'Login as user',
            html: `<p>Here is user login link: (link is valid for 2 minutes)</p><a target="_blank" href="${response}">${response}</a>`,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
}
</script>
