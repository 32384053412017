<template>
  <div v-if="loaded">
    <user-detail />
    <app-collapse>
      <app-collapse-item
        class="my-1"
        title="Verification attempts"
      >
        <user-verification-attempts-list />
      </app-collapse-item>
    </app-collapse>
    <app-collapse>
      <app-collapse-item
        class="my-1"
        title="Account type settings"
      >
        <user-account-type-settings-list />
      </app-collapse-item>
    </app-collapse>
    <hr>
    <user-account-table />
    <hr>
    <user-orders-table />
    <hr>
    <user-refund-table :user-id="userId" />
    <hr>
    <user-withdrawal-gql-table />
  </div>
</template>

<script>
import { dispatch } from 'vuex-pathify'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import UserDetail from '@/views/user/detail/components/UserDetail.vue'
import UserVerificationAttemptsList from '@/views/user/detail/components/UserVerificationAttemptsList.vue'
import UserAccountTypeSettingsList from '@/views/user/detail/components/UserAccountTypeSettingsList.vue'
import UserRefundTable from '@/components/entity/refund/UserRefundTable.vue'
import UserAccountTable from '@/components/entity/account-gql/UserAccountTable.vue'
import UserOrdersTable from '@/components/entity/order/UserOrdersTable.vue'
import UserWithdrawalGqlTable from '@/components/entity/withdrawalGql/UserWithdrawalGqlTable.vue'

export default {
  components: {
    UserWithdrawalGqlTable,
    UserOrdersTable,
    UserAccountTable,
    UserRefundTable,
    UserVerificationAttemptsList,
    UserDetail,
    AppCollapse,
    AppCollapseItem,
    UserAccountTypeSettingsList,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    userId() {
      return this.$route.params.id
    },
  },
  beforeMount() {
    dispatch('usersList/get', this.userId).then(() => {
      this.loaded = true
    }).catch(err => {
      if (err.status === 404) {
        this.$router.push({ name: 'error-404' })
      }
    })
  },
}
</script>
