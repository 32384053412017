<template>
  <div>
    <b-card>
      <b-button-toolbar>
        <b-button-group>
          <b-button
            variant="primary"
            class="ml-auto"
            @click="onCreate"
          >
            New order
          </b-button>
          <b-button
            v-b-modal.orderTagsModal
            variant="secondary"
          >
            Assign tags to orders
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card>
    <gql-table
      ref="orderTable"
      :query="query"
      :fields="columns"
      query-type="orders"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="actions"
      :title="tableTitle"
      :toggle-filters="toggleFilters"
      @detail="onDetail"
      @edit="onEdit"
      @report="onReport"
      @reportDispute="onDisputeReport"
      @refund="onRefund"
      @remove="onOrderDelete"
    >
      <template #cell(order_number)="data">
        <b-link :to="{name: 'order-detail', params: {id: data.value}}">
          {{ data.value }}
        </b-link>
      </template>

      <template #cell(tags)="data">
        <tags-list :tags="data.item.tags" />
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.price, 'USD') }}
      </template>

      <template #cell(payment_datetime)="data">
        {{ formatDateTime(data.item.payment_datetime) }} {{ data.item.processor !== null ? `(${data.item.processor})` : '' }}
      </template>

      <template #cell(product)="data">
        <span v-if="data.item.product">
          {{ data.item.product.name }} | {{ data.item.product.platform }}
        </span>
        <span v-else>
          {{ data.item.item_description }}
        </span>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
      </template>
    </gql-table>
    <b-modal
      id="orderModal"
      :title="title"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <order-form
        :order="orderEdit"
        @submit="onEditSubmit()"
      />
    </b-modal>
    <b-modal
      id="orderTagsModal"
      title="Assign tags to orders"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <multiple-tag-form
        items-name="Order numbers"
        :is-busy="tagsToOrdersLoading"
        show-assign-to-users-btn
        show-purchase-disabled-option
        @submit="onSubmitMultipleTags"
      />
    </b-modal>
    <b-modal
      id="orderReportModal"
      title="Report generator"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <report-form
        :order="orderReport"
        @close="onCloseModal"
      />
    </b-modal>
    <b-modal
      id="refundModal"
      title="Create refund"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <refund-form
        :refund="refundEdit"
        @submit="onCloseRefundModal()"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge, BRow, BCol, BLink, BCard, BButtonGroup, BButton, BModal, BButtonToolbar,
} from 'bootstrap-vue'
import { dispatch } from 'vuex-pathify'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import { formatCurrency, formatDateTime } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import TagsList from '@/components/page/TagsList.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import OrdersApi from '@/api/order'
import OrderForm from '@/components/forms/order/OrderForm.vue'
import MultipleTagForm from '@/components/forms/tag/MutipleTagForm.vue'
import ReportForm from '@/views/order/components/ReportForm.vue'
import RefundForm from '@/components/forms/order-refund/RefundForm.vue'
import { ORDER_STATE_VARIANTS } from '@/enums/orders'

const actions = [
  {
    text: 'Order details',
    icon: 'SearchIcon',
    emit: 'detail',
  },
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Generate report',
    icon: 'PaperclipIcon',
    emit: 'report',
  },
  {
    text: 'Generate dispute report',
    icon: 'PaperclipIcon',
    emit: 'reportDispute',
  },
  {
    text: 'Create refund',
    icon: 'EditIcon',
    emit: 'refund',
  },
  {
    text: 'Remove order',
    icon: 'Trash2Icon',
    emit: 'remove',
  },
]
export default {
  components: {
    RefundForm,
    GqlTable,
    BCol,
    BRow,
    BBadge,
    BLink,
    BCard,
    BButtonGroup,
    BButton,
    BModal,
    BButtonToolbar,
    TagsList,
    RiskScoreBadge,
    OrderForm,
    MultipleTagForm,
    ReportForm,
  },
  props: {
    columns: Array,
    toggleFilters: {
      type: Boolean,
      default: false,
    },
    tableTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      orderEdit: null,
      orderReport: null,
      title: '',
      tagsToOrdersLoading: false,
      refundEdit: null,

      actions,
      query: [
        'discount',
        'due_datetime',
        'id',
        'ip',
        'item_description',
        'order_number',
        'original_price',
        'payment_datetime',
        'price',
        'processor',
        'state',
        'createdAt',
        {
          user: {
            fields: [
              'firstName',
              'lastName',
              'id',
              'email',
              'risk_score',
              'risk_score_results',
              'kyc_at',
              'purchase_disabled',
              { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }],
          },
        },
        { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
        {
          product: {
            fields: [
              'name',
              'platform',
            ],
          },
        },
      ],
      stateVariants: ORDER_STATE_VARIANTS,
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    onCreate() {
      this.title = 'Order create'
      this.orderEdit = null
      this.$bvModal.show('orderModal')
    },
    onEdit(item) {
      this.title = 'Order edit'
      dispatch('ordersList/get', item.order_number).then(data => {
        this.orderEdit = data.order_data
        this.$bvModal.show('orderModal')
      })
    },
    onEditSubmit() {
      this.$bvModal.hide('orderModal')
      this.$refs.orderTable.reloadAll(true)
    },
    onDetail(item) {
      this.$router.push({ name: 'order-detail', params: { id: item.order_number } })
    },
    onReport(item) {
      OrdersApi.generateOrderReport(item.id)
    },
    onDisputeReport(item) {
      this.orderReport = item
      this.$bvModal.show('orderReportModal')
    },
    onCloseModal() {
      this.orderReport = null
      this.$bvModal.hide('orderReportModal')
    },
    onSubmitMultipleTags(data) {
      this.tagsToAccountsLoading = true
      OrdersApi.postTagsToOrders(data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tags was assigned to orders',
              icon: 'SendIcon',
              text: 'Tags was successfully assigned to orders.',
              variant: 'success',
            },
          })
          this.$bvModal.hide('orderTagsModal')
        })
        .catch(error => {
          if (error.status === 400) {
            Swal.fire({
              title: 'Some tags was not assign to orders',
              text: error.data.detail,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            })
          }
        })
        .finally(() => {
          this.$refs.orderTable.reloadAll(true)
          this.tagsToOrdersLoading = false
        })
    },
    onRefund(item) {
      if (item.state !== 'paid') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Only paid orders can be refunded.',
            icon: 'SendIcon',
            variant: 'danger',
          },
        })
      }

      dispatch('ordersList/get', item.order_number).then(data => {
        this.refundEdit = { order: data }
        this.$bvModal.show('refundModal')
      })
    },
    onCloseRefundModal() {
      this.refundEdit = null
      this.$bvModal.hide('refundModal')
      this.$refs.orderTable.reloadAll(true)
    },
    onOrderDelete(item) {
      Swal.fire({
        title: 'Remove this order',
        text: 'Are you sure you want to remove this order?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          OrdersApi.removeOrder(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Remove successful',
                  icon: 'TrashIcon',
                  text: 'Row was successfully removed',
                  variant: 'success',
                },
              })
              this.$refs.orderTable.reloadAll(true)
            })
        }
      })
    },
  },
}
</script>
