<template>
  <div>
    <b-button
      v-b-modal.settingsModal
      variant="secondary"
      size="sm"
      class="mb-1"
    >
      Add settings
    </b-button>
    <b-table
      :items="user.account_type_settings"
      :fields="fields"
      striped
      responsive
      small
    >
      <template #cell(account_type)="data">
        {{ ACCOUNTS[data.value.name].title }}
      </template>

      <template #cell(actions)="data">
        <action-list
          :items="actions"
          @remove="onRemove(data.item.id)"
        />
      </template>
    </b-table>
    <b-modal
      id="settingsModal"
      ref="settings-modal"
      title="Account type settings"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <b-form @submit.prevent="onSubmit">
        <label>Products</label>
        <v-select
          v-model="form.accountTypes"
          :options="Object.values(ACCOUNTS)"
          label="title"
          :multiple="true"
          :reduce="item => item.id"
        />
        <label>Leverage</label>
        <b-form-input
          v-model.number="form.leverage"
          type="number"
          min="0"
        />
        <b-button
          type="submit"
          class="mt-2"
        >
          Submit
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { dispatch, get } from 'vuex-pathify'
import {
  BTable, BModal, BButton, BFormInput, BForm,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import ActionList from '@/components/ui/ActionList.vue'
import table from '@/mixins/table'
import { formatDateTime } from '@/plugins/formaters'
import { ACCOUNTS } from '@/enums/accounts'
import UserAPI from '@/api/user'

const actions = [
  {
    text: 'Remove',
    icon: 'Trash2Icon',
    emit: 'remove',
  },
]

export default {
  components: {
    BTable,
    BModal,
    BButton,
    BFormInput,
    BForm,
    ActionList,
    vSelect,
  },
  mixins: [table],
  data() {
    return {
      ACCOUNTS,
      fields: [
        {
          key: 'account_type',
          sortable: false,
        },
        {
          key: 'leverage',
          sortable: false,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      actions,
      form: { accountTypes: null, leverage: null },
    }
  },
  computed: {
    ...get('usersList@row', { user: 'user_data' }),
  },
  methods: {
    formatDateTime,
    onRemove(id) {
      UserAPI.deleteAccountSettingsToUser(id)
        .then(() => {
          dispatch('usersList/get', this.user.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted',
              icon: 'SendIcon',
              text: 'Setting was removed',
              variant: 'success',
            },
          })
        })
    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
      const products = []
      this.form.accountTypes.forEach(id => {
        products.push({ id, leverage: this.form.leverage })
      })

      UserAPI.postAccountSettingsToUser(this.user.id, { accountTypeSettings: products })
        .then(() => {
          this.$parent.loading = true
          dispatch('usersList/get', this.user.id).finally(() => {
            this.$parent.loading = false
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated',
              icon: 'Edit3Icon',
              text: 'Account type setting created',
              variant: 'success',
            },
          })
          this.$refs['settings-modal'].hide()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'Edit3Icon',
              text: 'Edit failed!',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
