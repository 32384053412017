<template>
  <div>
    <b-table
      :items="user.verifications"
      :fields="fields"
      striped
      responsive
      small
    >
      <template #cell(show_details)="data">
        <b-button
          v-b-modal.veriffModal
          variant="outline-primary"
          class="btn-icon ml-1"
          @click.prevent="verification = data.item"
        >
          <feather-icon icon="UserCheckIcon" />
        </b-button>
      </template>

      <template #cell(status)="data">
        <b-badge
          v-if="data.item.status === 'approved'"
          variant="light-success"
        >
          {{ data.item.status }}
        </b-badge>
        <b-badge
          v-else
          variant="light-danger"
        >
          {{ data.item.status }}
        </b-badge>
      </template>

      <template #cell(date)="data">
        {{ formatDateTime(data.item.decision.createdAt) }}
      </template>

      <template #cell(actions)="data">
        <action-list
          :items="actions"
          @report="onReport(data.item)"
        />
      </template>
    </b-table>
    <b-modal
      id="veriffModal"
      title="Verification details"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <veriff-detail
        :item="verification"
      />
    </b-modal>
  </div>
</template>
<script>
import { get } from 'vuex-pathify'
import {
  BTable, BBadge, BButton, BModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ActionList from '@/components/ui/ActionList.vue'
import table from '@/mixins/table'
import { formatDateTime } from '@/plugins/formaters'
import VeriffDetail from '@/components/page/JsonRecursiveList.vue'
import UserAPI from '@/api/user'

const actions = [
  {
    text: 'Export report',
    icon: 'ExternalLinkIcon',
    emit: 'report',
  },
]

export default {
  components: {
    BTable,
    BBadge,
    BButton,
    BModal,
    ActionList,
    VeriffDetail,
  },
  mixins: [table],
  data() {
    return {
      fields: [
        {
          key: 'show_details',
          sortable: false,
        },
        {
          key: 'status',
          sortable: false,
        },
        {
          key: 'date',
          sortable: false,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      actions,
      verification: null,
    }
  },
  computed: {
    ...get('usersList@row', { user: 'user_data' }),
  },
  methods: {
    formatDateTime,
    onReport(item) {
      UserAPI.generateVeriffReport(item.id, item.sessionId, this.user.email)
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Export failed',
              icon: 'XCircleIcon',
              text: error.statusText,
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
