<template>
  <orders-table-gql
    :columns="columns"
    table-title="Orders"
    :toggle-filters="true"
  />
</template>

<script>
import { get } from 'vuex-pathify'
import { ORDER_STATE_VARIANTS, PAYMENT_METHODS } from '@/enums/orders'
import OrdersTableGql from '@/components/entity/order/OrdersTableGql.vue'
import { DEFAULT_PLATFORMS_SWITCHER } from '@/enums/accounts'

export default {
  components: {
    OrdersTableGql,
  },
  computed: {
    ...get('usersList@row', { user: 'user_data' }),
    columns() {
      return [
        {
          name: 'searchString', label: 'Quick search', filterable: 'like', visible: false,
        },
        {
          name: 'id', label: 'ID', filterable: 'eq', visible: false,
        },
        {
          name: 'order_number', label: 'Order number', filterable: 'like',
        },
        {
          name: 'tags',
          label: 'Tag',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
        },
        {
          name: 'user',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: false,
          },
          default: this.user.id,
        },
        {
          name: 'price', label: 'Price', filterable: 'range', default: [1, null], sortable: true,
        },
        {
          name: 'product',
          label: 'Product',
          sortable: false,
        },
        {
          name: 'createdAt', label: 'Created at', filterable: 'range', sortable: true,
        },
        {
          name: 'payment_datetime', label: 'Payment at', filterable: 'range', sortable: true,
        },
        { name: 'state', label: 'State', filterable: { type: 'multienum', enum: Object.keys(ORDER_STATE_VARIANTS) } },
        {
          name: 'processor',
          label: 'Processor',
          filterable: {
            type: 'multienum',
            enum: PAYMENT_METHODS,
          },
          visible: false,
        },
        {
          name: 'productPlatform',
          label: 'Product platform',
          filterable: {
            type: 'multienum',
            enum: DEFAULT_PLATFORMS_SWITCHER,
          },
          visible: false,
        },
      ]
    },
  },
}
</script>
