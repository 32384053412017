const REFUND_STATES = {
  requested: {
    name: 'requested',
    title: 'Requested',
    variant: 'primary',
  },
  completed: {
    name: 'completed',
    title: 'Completed',
    variant: 'success',
  },
  declined: {
    name: 'declined',
    title: 'Declined',
    variant: 'danger',
  },
}

export { REFUND_STATES }
