<template>
  <withdrawal-gql-table
    :columns="columns"
    :show-action-bar="false"
    title="Payouts"
  />
</template>
<script>
import { get } from 'vuex-pathify'
import { ACCOUNTS_SHORT } from '@/enums/accounts'
import WithdrawalGqlTable from '@/components/entity/withdrawalGql/WithdrawalGqlTable.vue'

export default {
  components: {
    WithdrawalGqlTable,
  },
  computed: {
    ...get('usersList@row', { user: 'user_data' }),
    columns() {
      return [
        {
          name: 'user',
          label: 'User',
          filterable: {
            type: 'eq', visible: false,
          },
          default: this.user.id,
        },
        {
          name: 'account',
          label: 'Account',
          filterable: {
            type: 'multientity', queryType: 'accounts', value: 'id', label: 'account_id', search: 'account_id',
          },
        },
        {
          name: 'Deposit',
          label: 'Deposit',
          sortable: false,
        },
        {
          name: 'current_balance',
          label: 'Current balance on account',
          sortable: true,
        },
        {
          name: 'captured_balance',
          label: 'Captured balance',
          sortable: true,
        },
        {
          name: 'amount',
          label: 'Profit share $',
          sortable: true,
        },
        {
          name: 'profit_share',
          label: 'Profit share %',
          sortable: true,
        },
        {
          name: 'withdrawal_datetime',
          label: 'Withdrawal time',
          sortable: true,
        },
        {
          name: 'state',
          label: 'State',
          sortable: false,
          filterable: {
            type: 'multienum',
            enum: ['requested', 'investigation', 'investigation - contacted', 'investigation - declined', 'approved by support', 'completed'],
          },
        },
        {
          name: 'platform',
          label: 'Platform',
          sortable: false,
        },
        {
          name: 'confirmed_at',
          label: 'Confirmed at',
          sortable: true,
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'stateAction',
          label: 'State action',
          sortable: false,
        },
        {
          name: 'accountDeposit',
          label: 'Deposit',
          filterable: 'range',
          default: [1, null],
          visible: false,
        },
        {
          name: 'accountType',
          label: 'Account type',
          filterable: { type: 'multienum', enum: Object.keys(ACCOUNTS_SHORT) },
          visible: false,
        },
      ]
    },
  },
}
</script>
